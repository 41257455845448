import React from "react"
import { useIntl, FormattedMessage, navigate } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"

import Layout, { Section } from "../layout"
import SEO from "../seo"
import Form, {
  FormRow,
  RadioGroup,
  RadioWrapper,
  FloatingLabelInput,
} from "../Form"
import { SubmitButton } from "../Button"
import Center from "../Center"
import { sendFormWithData } from "../../helpers/formBot"

interface IFormData {
  firstName: string
  lastName: string
  email: string
  phone: string
  type: string
  message: string
}

export default function Quote() {
  const intl = useIntl()
  const { register, handleSubmit, errors, formState } = useForm<IFormData>()

  const onSubmit = async (data: IFormData) => {
    const result = await sendFormWithData("Quote Request", data)

    if (!result.ok || result.status !== 200) {
      alert(intl.formatMessage({ id: "quote.formError" }))
      return
    }

    // Redirect
    navigate(
      intl.formatMessage({
        id: "pages.thankYouForContactingUs",
      }),
      {
        state: {
          email: data.email,
        },
      }
    )
  }

  return (
    <Layout skipHeader>
      <SEO lang={intl.locale} title={intl.formatMessage({ id: "title" })} />
      <Section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>
          <FormattedMessage id="quote.weRespondIn" />
        </p>
        <h2>
          <FormattedMessage id="quote.title" />
        </h2>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FloatingLabelInput className={errors.firstName ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({ id: "quote.formName" })}
                type="text"
                name="firstName"
                id="firstName"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="firstName">
                {intl.formatMessage({ id: "quote.formName" })}
              </label>
              {errors.firstName && (
                <span className="has-error">{errors.firstName.message}</span>
              )}
            </FloatingLabelInput>
            <FloatingLabelInput className={errors.lastName ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({ id: "quote.formLastName" })}
                type="text"
                name="lastName"
                id="lastName"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="lastName">
                {intl.formatMessage({ id: "quote.formLastName" })}
              </label>
              {errors.lastName && (
                <span className="has-error">{errors.lastName.message}</span>
              )}
            </FloatingLabelInput>
          </FormRow>
          <FormRow>
            <FloatingLabelInput className={errors.email ? "has-error" : ""}>
              <input
                placeholder={intl.formatMessage({ id: "quote.formEmail" })}
                type="text"
                name="email"
                id="email"
                ref={register({
                  required: {
                    value: true,
                    message: intl.formatMessage({ id: "formRequired" }),
                  },
                })}
              />
              <label htmlFor="email">
                {intl.formatMessage({ id: "quote.formEmail" })}
              </label>
              {errors.email && (
                <span className="has-error">{errors.email.message}</span>
              )}
            </FloatingLabelInput>
            <FloatingLabelInput>
              <input
                placeholder={intl.formatMessage({
                  id: "quote.formPhoneNumber",
                })}
                type="tel"
                name="phone"
                id="phone"
                ref={register}
              />
              <label htmlFor="phone">
                {intl.formatMessage({ id: "quote.formPhoneNumber" })}
              </label>
            </FloatingLabelInput>
          </FormRow>
          <RadioGroup>
            <RadioWrapper>
              <input
                type="radio"
                id="typeBuilding"
                name="type"
                value="building"
                ref={register}
              />
              <label htmlFor="typeBuilding">
                {intl.formatMessage({ id: "quote.formTypeBuilding" })}
              </label>
            </RadioWrapper>
            <RadioWrapper>
              <input
                type="radio"
                id="typeCommunity"
                name="type"
                value="community"
                ref={register}
              />
              <label htmlFor="typeCommunity">
                {intl.formatMessage({ id: "quote.formTypeCommunity" })}
              </label>
            </RadioWrapper>
            <RadioWrapper>
              <input
                type="radio"
                id="typeOther"
                name="type"
                value="other"
                ref={register}
              />
              <label htmlFor="typeOther">
                {intl.formatMessage({ id: "quote.formTypeOther" })}
              </label>
            </RadioWrapper>
          </RadioGroup>
          <label htmlFor="message">
            {intl.formatMessage({ id: "quote.formMessage" })}
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            ref={register}
          ></textarea>
          <br />
          <Center>
            <SubmitButton
              type="submit"
              disabled={formState.isSubmitting}
              value={intl.formatMessage({
                id: formState.isSubmitting
                  ? "quote.formSubmitting"
                  : "quote.formSubmit",
              })}
            />
          </Center>
        </Form>
        <br />
        <h5>
          <FormattedMessage id="quote.formFooter" />
        </h5>
      </Section>
    </Layout>
  )
}
